@font-face {
  font-family: "TimesDotRom";
  src: local("TimesDotRom"), url(./font/TimesDotRom.woff) format("woff");
}
@font-face {
  font-family: "TimesDotItalic";
  src: local("TimesDotItalic"), url(./font/TimesDot-Italic.woff) format("woff");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  /* box-sizing: border-box; */
  overflow: hidden;
  margin: 0;
  font-family: "TimesDotRom", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: clamp(1.125rem, 0.724rem + 0.7921vw, 2.625rem);
  /* font-size: clamp(1.125rem, 0.9107rem + 0.4464vw, 1.625rem); */
  line-height: 1.2;
  color: #fff;
  background-color: #000;
}
.scroll {
  overflow-y: auto !important;
}

.italic {
  font-family: "TimesDotItalic", sans-serif;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

/* Header */
.navigation {
  align-items: center;
  mix-blend-mode: difference;
  position: fixed;
  z-index: 100;
  height: clamp(3rem, 2.7321rem + 0.558vw, 3.625rem);
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.navigation a {
  padding: 15px;
  color: #fff;
  text-decoration: none;
}
/* 
.navigation span {
  padding: 15px;
} */

/* About page */
.about {
  padding: 5rem 15px 15px 15px;
  display: flex;
  flex-direction: row;
  padding-top: 5rem;
  margin-bottom: 50px;
  width: 85%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}

.block-1 {
  /* box-sizing: border-box;   */
  padding: 15px 4rem 15px 0;
  width: 55%;
  /* padding-right: 4rem; */
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 15px 0 15px 0 !important;
  }
}

.block-2 {
  /* box-sizing: border-box; */
  padding: 15px 0;
  width: 25%;
  padding-right: 4rem;
  .colab {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding:0;
  }
}

.intl {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: clamp(1.125rem, 1.0179rem + 0.2232vw, 1.375rem);
}
.contract {
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
}

.info,
.teach {
  padding-bottom: 1em;
}

.base {
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.colophon {
  width: 100%;
  /* padding-bottom: 1em; */
  display: flex;
  flex-direction: row;
  .typeface {
    width: 25%;
    display: flex !important;
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
      width: 30%;
    }
  }
  .development {
    width: 75%;
    display: flex !important;
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
      width: 70%;
    }
  }
}

/* Flitity */

.carousel {
  width: 100%;
  height: 100%;
  img {
    display: block;
    margin: auto;
    object-fit: cover;
    object-position: center;
    width: 100vw;
    height: 100vh;
    @media (max-width: 768px) {
      height:100dvh;
    }
  }

  video {
    display: block;
    margin: auto;
    object-fit: cover;
    object-position: center;
    width: 100vw;
    height: 100vh;
    @media (max-width: 768px) {
      height:100dvh;
    }
  }
  .flickity-cell {
    transform: none !important;
    /* transform: translateX(-50%); opacity: 0; */
    &.is-selected {
      opacity: 1 !important;
    }
  }
  .flickity-slider {
    transform: none !important;
  }

  .flickity-cell {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* transition: none; */
    z-index: -1;
    &.is-selected {
      opacity: 1;
      z-index: 0;
    }
  }
}
.carousel-main {
  opacity: 0;
  transition: opacity 0.5s;
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 51px;

  .flickity-viewport {
    .flickity-slider {
      width: 100%;
      height: 51px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 5;
      top: 0;
      bottom: 0;
      left: 50%;
      border-left: 3px solid #00ff00;
      transform: translate(-50%);
    }

    .flickity-cell {
      /* display: flex; */
      /* height:100vh; */
      img {
        object-fit: cover;
        height: 51px;
        width: clamp(1.5625rem, -0.7411rem + 4.7991vw, 6.9375rem);
        border: 1px rgb(255, 255, 255) solid;
        /* @media only screen and (max-width: 768px) {
     
        } */
      }
    }
  }
  &.fadenav {
    opacity: 0 !important;
  }
}

.flickity-slider .flickity-viewport video {
  display: none;
}

.flickity-slider .flickity-viewport.is-selected video {
  display: block;
}
