.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Flickity */
.flickity-viewport {
  transition: none !important;  
}

.flickity-as-nav-for {
  width: 100%;
}

.no-reader-mode ::-webkit-any(article, aside, nav, section) {
  -webkit-line-break: normal;
  line-break: auto;
}
/* .flickity-slider{ transform: none !important; }
.slider__item{
    left: 0 !important;
   opacity: 0;
   transition: "opacity 0.3s ease-in-out";
}
.slider__item.is-selected{
    opacity: 1;
} */
